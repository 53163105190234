/* You can add global styles to this file, and also import other style files */

// Theme customization

// @import '~ngx-toastr/toastr.css';
@import "assets/theme/select-theme.css";

::ng-deep  .dataTables_wrapper .dataTables_paginate
{
  float:none;
  padding: 3px;
  background-color: rgb(221, 221, 221);
}
::ng-deep .dataTables_wrapper .dataTables_paginate .paginate_button:hover , ::ng-deep .dataTables_wrapper .dataTables_paginate .paginate_button:active
{
background-color: rgb(245, 245, 245) !important;
border-width: 1px !important;
border-style: solid !important;
border-color: rgb(170, 170, 170) !important;
color: #333 !important;
border-image: initial !important;
background: none;
}

::ng-deep  .dataTables_wrapper .dataTables_paginate .paginate_button,::ng-deep  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled   {
    padding: 3px 4px !important;
    cursor: pointer !important;
    display: inline-block !important;
    border-radius: 3px !important;
    border: 1px solid #ccc !important;
    background-color: white !important;
    box-shadow: none !important;
    text-shadow: none !important;
}
.dataTables_wrapper {
    display: grid;
    width: 100%;
    grid-template:
        "tb tb "
        "info pg"
        / minmax(200px, 1fr) 105px;
}
.dataTable {
  grid-area: tb;
}

.dataTables_info {
  grid-area: info;
  background-color: rgb(221, 221, 221);
}

.dataTables_paginate {
  grid-area: pg;
  margin-top: 0 !important;
}

.dropdown-search::after {
    content: "\f002";
    display: inline-block;
    color: #888;
    font-family: FontAwesome;
    font-size: 14px;
    position: absolute;
    top: 8px;
    right: 13px;
  }

  .align-right {
    text-align: right;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  


.bs-datepicker-body table td span {
    border-radius: 0px !important;
  }
  
  .bs-datepicker-container {
    padding: 0px !important;
  }
  .theme-green .bs-datepicker-body table td span.selected {
    background-color: #3379b6 !important;
  }
  .bs-datepicker-body table td {
    color: #333 !important;
  }
  .bs-datepicker-body table th {
    color: #333 !important;
    font-weight: 600 !important;
  }
  .bs-datepicker-head button {
    color: #333 !important;
    font-weight: 600 !important;
  }
  .bs-datepicker-body table td span.is-other-month {
    color: #333 !important;
  }
  
  .bs-datepicker-bodytodayselected {
    background-color: #3379b6 !important;
    color: #fff;
  }
  
.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #9aaec1 !important;
}
.theme-green .bs-datepicker-body table td.week span {
  display: none;
}
.bs-datepicker-head button {
  color: #000 !important;
}

.theme-green .bs-datepicker-head {
  background-color: #fff !important;
}

.bs-datepicker-body table.dataTable.row-border tbody td,
th,
tr {
  border-left: none;
}
.bs-datepicker-body table td span {
border-radius :0px !important
}

.bs-datepicker-container{
padding:0px !important
}
.theme-green .bs-datepicker-body table td span.selected{
background-color:#3379b6 !important;
}
.bs-datepicker-body table td {
color: #333 !important;
}
.bs-datepicker-body table th{
color: #333 !important;
font-weight: 600 !important;
}
.bs-datepicker-head button {
color: #333 !important;
font-weight: 600 !important;
}
.bs-datepicker-body table td span.is-other-month{
color: #333!important;
}

button{
cursor: pointer;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span
{
color: #9aaec1 !important;
}
.theme-green .bs-datepicker-body table td.week span{
display: none;
}